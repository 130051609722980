import classes from "../RegistMerchant.module.css";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Button, Typography } from "@mui/material";
import getInitials from "../../General/Helper/getInitials";
import ExpandLessIcon from "../../General/Icon/ExpandLessIcon";
import ExpandMoreIcon from "../../General/Icon/ExpandMoreIcon";
import HeaderComponent from "../../General/Component/HeaderComponent";
import AccordionPreview from "../Component/AccordionPreview";

const PreviewData = () => {
  const userDataLogin = useSelector((state) => state.user_data.userDataLogin);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openNavbar, setOpenNavbar] = useState(false);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleOpenNavbar = () => {
    setOpenNavbar(!openNavbar);
  };

  const userActionLogin = (
    <Button
      className={classes.buttonUserName}
      aria-describedby={id}
      onClick={handleClick}
      disableRipple
    >
      <div className={classes.divProfile}>
        {getInitials(userDataLogin?.fullname)}
        <div className={classes.divName}>
          <Typography className={classes.textBlackSemiBold14}>
            {userDataLogin?.fullname}
          </Typography>
        </div>
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </div>
    </Button>
  );

  return (
    <>
      <HeaderComponent
        userAction={userActionLogin}
        isNavbar={true}
        handleOpenNavbar={handleOpenNavbar}
      />
      <div className={classes.divContainer}>
        <div className={classes.divContainerPreview}>
          <AccordionPreview />
        </div>
      </div>
    </>
  );
};

export default PreviewData;

import React from "react";
import classes from "../Style/general.module.css";
import SuccessIcon from "../Icon/SuccessIcon";
import ErrorIcon from "../Icon/ErrorIcon";
import WarningIcon from "../Icon/WarningIcon";
import InfoIcon from "../Icon/InfoIcon";

const InfoComponent = React.forwardRef(({ severity, message }, ref) => {
  const icons = {
    success: <SuccessIcon width={24} height={24} />,
    error: <ErrorIcon width={24} height={24} />,
    warning: <WarningIcon width={24} height={24} />,
    info: <InfoIcon width={24} height={24} />,
  };

  const classNames = {
    success: classes.divSuccess,
    error: classes.divFailed,
    warning: classes.divWarning,
    info: classes.divInfo,
  };

  return (
    <div
      ref={ref}
      className={`${classNames[severity]} ${classes.textRegularBlack12}`}
    >
      {icons[severity]}
      {message}
    </div>
  );
});

export default InfoComponent;

import { t } from "i18next";
import {
  AddressSchema,
  AlphanumericSchema,
  AlphanumericWithDashSpace,
  AmountSchema,
  EmailSchema,
  IDCardSchema,
  NPWPSchema,
  OnlyNumberSchema,
  PassportSchema,
  PhoneNumberSchema,
  RegularSchema,
  URLSchema,
} from "./validationField";

export const isValidEmail = (email) => EmailSchema(t).safeParse(email).success;

export const isValidName = (name) => RegularSchema(t).safeParse(name).success;

export const isValidPhone = (phone) =>
  PhoneNumberSchema(t).safeParse(phone).success;

export const isValidPassport = (passport) =>
  PassportSchema(t).safeParse(passport).success;

export const isValidIDCard = (idCard) =>
  IDCardSchema(t).safeParse(idCard).success;

export const isValidNPWP = (npwp) => NPWPSchema(t).safeParse(npwp).success;

export const isValidURL = (url) => URLSchema(t).safeParse(url).success;

export const isValidAddress = (address) =>
  AddressSchema(t).safeParse(address).success;

export const isValidTime = (address) =>
  AlphanumericSchema(t).safeParse(address).success;

export const isValidTitlePerson = (title) =>
  AlphanumericWithDashSpace(t).safeParse(title).success;

export const isValidAmount = (amount) =>
  AmountSchema(t).safeParse(amount?.replace(/\./g, "")).success;

export const isValidNumber = (number) =>
  OnlyNumberSchema(t).safeParse(number?.replace(/\./g, "")).success;

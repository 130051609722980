// validationSchemas.js
import { z } from "zod";
import strings from "../Constant/Locales/Strings/GeneralStrings";

const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{8,}$/;
const regularRegex = /^[a-zA-Z\s.'-]+$/;
const phoneNumberRegex = /^8\d{7,10}$/;
const alphanumericRegex = /^[A-Za-z0-9]+$/;
const alphanumericWithSpaceRegex = /^[A-Za-z0-9 ]+$/;
const alphanumericWithSpaceAndDashRegex = /^[A-Za-z0-9 \-]+$/;

export const FieldRequired = (t, label) =>
  z.string().min(1, label + " " + t(strings.textIsRequired));

export const EmailSchema = (t) =>
  z
    .string()
    .min(1, t(strings.textValidationEmailIsRequired))
    .email(t(strings.textValidationEmailFormat));

export const PhoneNumberSchema = (t) =>
  z
    .string()
    .min(1, t(strings.textValidationPhoneNumberIsRequired))
    .refine((value) => phoneNumberRegex.test(value), {
      message: t(strings.textValidationPhoneNumberFormat),
    });

export const RegularSchema = (t, label) =>
  z
    .string()
    .min(1, label + " " + t(strings.textIsRequired))
    .refine((value) => regularRegex.test(value), {
      message: label + " " + t(strings.textValidationLettersAndSomeSymbols),
    });

export const PasswordSchema = (t) =>
  z
    .string()
    .min(1, t(strings.textValidationPasswordIsRequired))
    .refine(
      (value) => {
        return passwordRegex.test(value);
      },
      { message: t(strings.textValidationPassword) }
    );

export const NPWPSchema = (t, label) =>
  z
    .string()
    .min(1, label + " " + t(strings.textIsRequired))
    .regex(/^\d+$/, {
      message: label + " " + t(strings.textValidationOnlyNumber),
    })
    .refine((value) => value.length === 15 || value.length === 16, {
      message: label + " " + t(strings.textMustBe15Or16Digits),
    });

export const IDCardSchema = (t, label) =>
  z
    .string()
    .min(1, label + " " + t(strings.textIsRequired))
    .regex(/^\d+$/, {
      message: label + " " + t(strings.textValidationOnlyNumber),
    })
    .refine((value) => value.length === 16, {
      message: label + " " + t(strings.textMustBe16Digits),
    });

export const URLSchema = (t, label) =>
  z
    .string()
    .min(1, label + " " + t(strings.textIsRequired))
    .url({ message: t(strings.textValidationURL) });

export const PassportSchema = (t, label) =>
  z
    .string()
    .min(6, label + " " + t(strings.textMustMin6chars))
    .max(9, label + " " + t(strings.textMustMax9chars))
    .regex(alphanumericRegex, label + " " + t(strings.textMustAlphanumeric));

export const OnlyNumberSchema = (t, label) =>
  z
    .string()
    .min(1, label + " " + t(strings.textIsRequired))
    .regex(/^\d+$/, label + " " + t(strings.textValidationOnlyNumber));

export const AmountSchema = (t, label) =>
  z
    .string()
    .min(1, label + " " + t(strings.textIsRequired))
    .regex(
      /^\d+(\.\d{1,2})?$/,
      label + " " + t(strings.textValidationOnlyNumber)
    );

export const AddressSchema = (t, label) =>
  z
    .string()
    .min(1, label + " " + t(strings.textIsRequired))
    .max(100, label + " " + t(strings.textMustMax100chars));

export const AlphanumericSchema = (t, label) =>
  z
    .string()
    .min(1, label + " " + t(strings.textIsRequired))
    .max(9, label + " " + t(strings.textMustMax9chars))
    .regex(
      alphanumericWithSpaceRegex,
      label + " " + t(strings.textMustAlphanumeric)
    );

export const AlphanumericWithDashSpace = (t, label) =>
  z
    .string()
    .min(1, label + " " + t(strings.textIsRequired))
    .regex(
      alphanumericWithSpaceAndDashRegex,
      label + " " + t(strings.textValidationLettersNumbersAndSomeSymbol)
    );

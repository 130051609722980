const strings = {
  textView: "View",
  textHide: "Hide",
  textCopyright: "©2023 PT IFORTE PAYMENT INFRASTUCTURE. All rights reserved.",
  textPageNotFoundTitle: "Oops! Page not found.",
  textPageNotFoundSubtitle:
    "The page you are looking for is temporarily unavailable.",
  textPageNotFoundCode: "Error code: 404",
  textBack: "Back",
  textFullName: "Full Name",
  textEmailSubject: "Email Subject",
  textCompanyName: "Company Name",
  textEmail: "Email",
  textPhoneNumber: "Phone Number",
  textMessage: "Message",
  textSendMessage: "Send Message",
  textSendEmail: "Send a Message by Email",
  textSubtitleContactUs:
    "Feel free to ask anything you want to know by sending a message through the form below.",
  textFailedActivationStatus: "Failed Activation Status",
  textPaymentAdministrationFee: "Payment Administration Fee",
  textMerchantVerificationProcess: "Merchant Verification Process",
  textAnotherIssues: "Another Issues",
  textOptional: "Optional",

  //PLACEHOLDER
  textPlaceholderFullName: "Enter your full name",
  textPlaceholderEmail: "Enter your email",
  textPlaceholderSubject: "Select your email subject",
  textPlaceholderCompanyName: "Enter your company name",
  textPlaceholderPhoneNumber: "8xxxxxxx",
  textPlaceholderMessage:
    "Example: Hi, I'm John. Why did my verification process fail?",

  // VALIDATION
  textIsRequired: "is Required",
  textValidationPasswordIsRequired: "Password is required.",
  textValidationPassword:
    "The password must consist of a minimum of 8 characters. Use a combination of uppercase letters (A-Z), lowercase letters (a-z), numbers, and special characters (Example: ?@#$).",
  textValidationEmailIsRequired: "Email is required.",
  textValidationEmailFormat: "Invalid email format",
  textValidationPhoneNumberIsRequired: "Phone number is required.",
  textValidationPhoneNumberFormat:
    "Invalid phone number format. Phone numbers must start with an 8, contain only numbers, and must consist of 10 to 13 digits.",
  textClose: "Close",
  textValidationLettersAndSomeSymbols:
    "consist of only letters and symbols such as . (Period) - (Hyphen) and ' (Apostrophe)",
  textValidationLettersNumbersAndSomeSymbol:
    "consists only of letters, numbers, spaces, and symbols such as - (hyphen)",

  textMustBe16Digits: "must be exactly 16 digits",
  textValidationOnlyNumber: "must be valid numbers",
  textValidationURL: "Invalid URL format",
  textMustMin6chars: "must be at least 6 characters long",
  textMustMax9chars: "must not exceed 9 characters",
  textMustMax100chars: "must not exceed 100 charactes",
  textMustAlphanumeric: "must be alphanumeric",
  textAccessDenied: "This page cannot be accessed",
  textDescAccessDenied:
    "Sorry, you do not have permission to access this page. Please return to the home page to continue your activity. If you feel this is a mistake, please do not hesitate to contact our support team.",
  textPageExpired: "Page is no longer available!",
  textDescPageExpired:
    "The link you are trying to access may have expired. Please return to the home page to continue your activity. If you feel this is an error, please do not hesitate to contact our support team.",
  textMustBe15Or16Digits: "must be at least 15 or 16 digits",
  textMaxFile5MB: "File size must be less than 5MB",
  textFileTypeMustBe: "File type must be",
};

export default strings;

import { VerificationStatus } from "../VerificationStatus";
import FormBusinessInfo from "./FormBusinessInfo";
import FormPIC from "./FormPIC";
import FormPaymentInfo from "./FormPaymentInfo";
import FormRegistrationInfo from "./FormRegistrationInfo";
import FormSupportingDocument from "./FormSupportingDocument";

const stepsMap = {
  0: [FormRegistrationInfo, FormPIC, FormBusinessInfo, FormPaymentInfo],
  1: [FormSupportingDocument, () => <>hello</>],
  3: [VerificationStatus],
};

const StepsController = ({
  activeSectionNumber,
  setActiveSectionNumber,
  activeStepOutlined,
  setActiveStepOutlined,
  progressData,
  setProgressData,
}) => {
  const CurrentStep = stepsMap[activeStepOutlined]?.[activeSectionNumber];

  if (CurrentStep) {
    return (
      <CurrentStep
        activeSectionNumber={activeSectionNumber}
        setActiveSectionNumber={setActiveSectionNumber}
        activeStepOutlined={activeStepOutlined}
        setActiveStepOutlined={setActiveStepOutlined}
        progressData={progressData}
        setProgressData={setProgressData}
      />
    );
  }

  return null;
};

export default StepsController;

import { useTranslation } from "react-i18next";
import useSnackbar from "../../General/Helper/useSnackbar";
import { useSelector } from "react-redux";
import SnackbarNotification from "../../General/Component/SnackbarNotification";
import { Typography } from "@mui/material";
import strings from "../../General/Constant/Locales/Strings/RegistMerchantStrings";
import classes from "../RegistMerchant.module.css";
import InfoComponent from "../../General/Component/InfoComponent";
import objectSupportingDoc from "../Constant/objectSupportingDoc";
import convertFileToBase64 from "../../General/Helper/convertFileToBase64";
import { useEffect, useState } from "react";
import { renderSupportingDoc } from "../Helper/renderSupportingDocs";
import Buttons from "../../General/Component/Buttons";
import LeftArrow from "../../General/Icon/LeftArrow";
import RightIcon from "../../General/Icon/RightIcon";
import getMerchantDetail from "../Helper/getMerchantDetail";

const FormSupportingDocument = ({
  activeSectionNumber,
  setActiveSectionNumber,
  activeStepOutlined,
  setActiveStepOutlined,
  progressData,
  setProgressData,
}) => {
  const { t } = useTranslation("RegistMerchant");
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();
  const userDataLogin = useSelector((state) => state.user_data.userDataLogin);
  const [formData, setFormData] = useState({
    fileDirectorKTP: { file: "", fileName: "" },
    filePassportDirector: { file: "", fileName: "" }, //belum
    fileKTPPIC: { file: "", fileName: "" }, //belum
    fileBankNumber: { file: "", fileName: "" },
    fileNPWP: { file: "", fileName: "" },
    fileTDPSE: { file: "", fileName: "" },
    fileDeedEstablishment: { file: "", fileName: "" },
    fileSKKemenkumham: { file: "", fileName: "" },
    fileNIB: { file: "", fileName: "" },
    fileInstitutionPermit: { file: "", fileName: "" },
    loadingSubmit: false,
  });

  const [merchantDetail, setMerchantDetail] = useState(null);
  const businessType = userDataLogin?.business_type;

  useEffect(() => {
    handleDisabledButton();
    calculateProgressValue();
  }, [formData]);

  useEffect(() => {
    getMerchantDetail(
      t,
      strings,
      activeSectionNumber,
      activeStepOutlined,
      setMerchantDetail,
      showSnackbar
    );
  }, [activeSectionNumber, activeStepOutlined, showSnackbar, t]);

  const calculateProgressValue = () => {
    const isCorporate = businessType === "Corporate";
    const {
      fileDirectorKTP,
      fileKTPPIC,
      filePassportDirector,
      fileTDPSE,
      fileDeedEstablishment,
      fileSKKemenkumham,
      fileInstitutionPermit,
      fileNIB,
      fileBankNumber,
      fileNPWP,
    } = formData;

    const progressConditions = [
      isCorporate ? filePassportDirector.file : fileDirectorKTP.file,
      isCorporate ? fileKTPPIC.file : null,
      fileBankNumber.file,
      fileNPWP.file,
      fileTDPSE.file,
      fileDeedEstablishment.file,
      fileSKKemenkumham.file,
      fileNIB.file,
      fileInstitutionPermit.file,
    ];

    const validProgressCount = progressConditions.filter(Boolean).length;
    const progressNumber =
      (validProgressCount / progressConditions.length) * 20;
    setProgressData((prev) => ({
      ...prev,
      step2: Math.ceil(progressNumber),
    }));
  };

  const handleChange = async (e) => {
    const { name, type, value } = e.target;

    if (type === "file") {
      const file = e.target.files[0];
      if (file) {
        try {
          const base64File = await convertFileToBase64(file);
          setFormData((prev) => ({
            ...prev,
            [name]: { file: base64File, fileName: file.name },
          }));
        } catch (error) {}
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleCloseFile = (e, name, label) => {
    e.preventDefault();
    e.stopPropagation();
    document.getElementById(label).value = "";

    setFormData((prev) => ({
      ...prev,
      [name]: {
        file: "",
        fileName: "",
      },
    }));
  };

  const handlePreviousSection = () => {
    setActiveStepOutlined((prev) => prev - 1);
    setActiveSectionNumber(3);
  };

  const handleDisabledButton = () => {
    return formData.loadingSubmit || businessType === "Corporate"
      ? !formData.filePassportDirector.file || !formData.fileKTPPIC.file
      : !formData.fileDirectorKTP.file ||
          !formData.fileBankNumber.file ||
          !formData.fileNPWP.file ||
          !formData.fileDeedEstablishment.file ||
          !formData.fileSKKemenkumham.file ||
          !formData.fileNIB.file ||
          !formData.fileInstitutionPermit.file;
  };

  const onSubmitSupportingDoc = () => {
    const {
      fileDirectorKTP,
      fileTDPSE,
      fileDeedEstablishment,
      fileSKKemenkumham,
      fileInstitutionPermit,
      fileNIB,
      fileBankNumber,
      fileNPWP,
    } = formData;

    const payload = {
      directorId: { ...fileDirectorKTP },
      tdpse: { ...fileTDPSE },
      amendmentDeed: { ...fileDeedEstablishment },
      ministryPermit: { ...fileSKKemenkumham },
      nib: {
        ...fileNIB,
      },
      companyPermit: {
        ...fileInstitutionPermit,
      },
      accountNumber: {
        ...fileBankNumber,
      },
      npwp: {
        ...fileNPWP,
      },
    };
  };
  return (
    <>
      <SnackbarNotification snackbar={snackbar} hideSnackbar={hideSnackbar} />
      <div className={classes.divFormStepper}>
        <div className={classes.groupWithSubtileSmall}>
          <Typography className={classes.textSuperBold18}>
            {t(strings.textSupportingDocuments)}
          </Typography>
          <Typography className={classes.textRegularDarkGray14}>
            {t(strings.textSubtitleSupportingDocs)}
          </Typography>
        </div>

        <div className={classes.divLine} />

        <div className={classes.groupWithSubtile}>
          <InfoComponent
            severity={"info"}
            message={t(strings.textInfoSupportingDoc)}
          />
          <div className={classes.divWrapFourColumn}>
            {objectSupportingDoc(t, strings)
              .reduce((rows, field, index) => {
                if (index % 2 === 0) {
                  rows.push([field]);
                } else {
                  rows[rows.length - 1].push(field);
                }
                return rows;
              }, [])
              .map((row) => {
                return row.map((field, index) => {
                  return renderSupportingDoc(
                    classes,
                    businessType,
                    field,
                    index,
                    formData,
                    handleChange,
                    showSnackbar,
                    handleCloseFile
                  );
                });
              })}
          </div>

          <div className={classes.divGroupButton}>
            <Buttons
              loadingSubmit={false}
              label={t(strings.textPrevious)}
              handleDisabledButton={() => false}
              onSubmitData={handlePreviousSection}
              leftIcon={<LeftArrow />}
              isSolid={false}
            />
            <Buttons
              loadingSubmit={formData.loadingSubmit}
              label={t(strings.textNext)}
              handleDisabledButton={handleDisabledButton}
              onSubmitData={onSubmitSupportingDoc}
              rightIcon={<RightIcon />}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FormSupportingDocument;

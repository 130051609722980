import FileUpload from "../../General/Component/FileUpload";

export const renderSupportingDoc = (
  classes,
  businessType,
  field,
  index,
  formData,
  handleChange,
  showSnackbar,
  handleCloseFile
) => {
  const isCorporate = businessType === "Corporate";
  const isNonCorporate = businessType === "Non Corporate";

  const corporateField = [
    "filePassportDirector",
    "fileKTPPIC",
    "fileBankNumber",
    "fileNPWP",
    "fileTDPSE",
    "fileDeedEstablishment",
    "fileSKKemenkumham",
    "fileNIB",
    "fileInstitutionPermit",
  ];

  const nonCorporateField = [
    "fileDirectorKTP",
    "fileBankNumber",
    "fileNPWP",
    "fileTDPSE",
    "fileDeedEstablishment",
    "fileSKKemenkumham",
    "fileNIB",
    "fileInstitutionPermit",
  ];

  if (isCorporate && corporateField.includes(field.name)) {
    return (
      <div className={classes.divHalf} key={index}>
        <FileUpload
          key={index}
          name={field.name}
          label={field.label}
          fileName={formData[field.name]?.fileName}
          placeholder={field.placeholder}
          icon={field.icon}
          extensionFile={field.extensionFile}
          notes={field.notes}
          isOptional={field.isOptional}
          onChange={handleChange}
          showSnackbar={showSnackbar}
          handleClose={handleCloseFile}
        />
      </div>
    );
  } else if (isNonCorporate && nonCorporateField.includes(field.name)) {
    return (
      <div className={classes.divHalf} key={index}>
        <FileUpload
          key={index}
          name={field.name}
          label={field.label}
          fileName={formData[field.name]?.fileName}
          placeholder={field.placeholder}
          icon={field.icon}
          extensionFile={field.extensionFile}
          notes={field.notes}
          isOptional={field.isOptional}
          onChange={handleChange}
          showSnackbar={showSnackbar}
          handleClose={handleCloseFile}
        />
      </div>
    );
  }
};

import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import classes from "../Style/general.module.css";

const SelectOptions = ({
  label,
  name,
  placeholder,
  value,
  listData,
  handleChange,
}) => {
  return (
    <div className={classes.divFormField}>
      <Typography className={classes.textBold600BlackH4}>{label}</Typography>
      <FormControl fullWidth>
        <Select
          displayEmpty
          value={value}
          name={name}
          onChange={handleChange}
          className={classes.selectOption}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        >
          <MenuItem value="" disabled>
            -- {placeholder || ""} --
          </MenuItem>
          {listData.map((data, index) => (
            <MenuItem value={data.id} key={index}>
              {data.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectOptions;

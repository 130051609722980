import {
  Drawer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Steppers from "../../General/Component/Steppers";
import CircularProgressBar from "../../General/Component/CircularProgressBar";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import strings from "../../General/Constant/Locales/Strings/RegistMerchantStrings";
import classes from "../RegistMerchant.module.css";

const NavbarStepper = ({
  openNavbar,
  handleOpenNavbar,
  activeStepOutlined,
  listOutlinedStep,
  progressData,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation("RegistMerchant");

  const progressList = [
    {
      label: t(strings.textRegistrationInformation),
      percentage: progressData.section1,
    },
    {
      label: t(strings.textPICName),
      percentage: progressData.section2,
    },
    {
      label: t(strings.textBusinessInformation),
      percentage: progressData.section3,
    },
    {
      label: t(strings.textServicesAndFees),
      percentage: progressData.section4,
    },
    {
      label: t(strings.textSupportingDocuments),
      percentage: progressData.step2,
    },
  ];

  useEffect(() => {
    calculateProgress();
  }, [progressData]);

  const calculateProgress = () =>
    progressData.section1 +
    progressData.section2 +
    progressData.section3 +
    progressData.section4 +
    progressData.step2;

  const renderSteppers = () => (
    <Steppers
      activeStep={activeStepOutlined}
      stepList={listOutlinedStep}
      orientationStepper="vertical"
      dots={true}
    />
  );

  const renderProgressBar = () => (
    <div>
      <Typography className={classes.textBold600Darker16}>
        {t(strings.textProgressScore)}
      </Typography>
      <div className={classes.divAllCenterColumn}>
        <div className={classes.divCircularProgress}>
          <CircularProgressBar percentage={calculateProgress()} />
        </div>
        <div className={classes.divProgressItems}>{showProgressItem()}</div>
      </div>
    </div>
  );

  const showProgressItem = () => {
    return (
      <Table>
        <TableHead>
          <TableRow></TableRow>
        </TableHead>
        <TableBody>
          {progressList.map((data, index) => {
            return (
              <TableRow key={index}>
                <TableCell align="left" className={classes.tableCell}>
                  <Typography className={classes.textRegularBold12}>
                    {data.label}
                  </Typography>
                </TableCell>
                <TableCell align="right" className={classes.tableCell}>
                  <Typography className={classes.textBold600Darker12}>
                    {data.percentage}%
                  </Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  return (
    <div className={classes.divLeftStep}>
      <div className={classes.divFixed}>
        {isMobile ? (
          <Drawer open={openNavbar} onClose={handleOpenNavbar}>
            <div className={classes.divContentLeft}>
              {renderSteppers()}
              {renderProgressBar()}
            </div>
          </Drawer>
        ) : (
          <div className={classes.divContentLeft}>
            {renderSteppers()}
            {renderProgressBar()}
          </div>
        )}
      </div>
    </div>
  );
};

export default NavbarStepper;

import UploadIcon from "../../General/Icon/UploadIcon";

const objectMerchantWNA = (t, strings) => [
  {
    name: "merchantName",
    placeholder: t(strings.textPlaceholderMerchantName),
    label: t(strings.textMerchantName),
    type: "text",
    isValidate: true,
  },
  {
    name: "address",
    placeholder: `${t(
      strings.textExample
    )}: Jl. Raya Sejahtera No. 12, Kelapa Gading, Jakarta, DKI Jakarta 14230, Indonesia`,
    label: t(strings.textMerchantAddress),
    type: "text",
    isValidate: true,
  },
  {
    name: "urlWeb",
    placeholder: t(strings.textPlaceholderWebsiteURL),
    label: t(strings.textURLWeb),
    type: "text",
    isValidate: true,
  },
  {
    name: "npwpNumberCompany",
    placeholder: t(strings.textPlaceholderCompanyNPWP),
    label: t(strings.textCompanyNPWP),
    type: "text",
    isValidate: true,
  },
  {
    name: "businessTime",
    placeholder: t(strings.textPlaceholderBusinessPeriod),
    label: t(strings.textBusinessLength),
    type: "text",
    isValidate: true,
  },
  {
    name: "employeeNumber",
    placeholder: t(strings.textPlaceholderChooseEmployeeNumber),
    label: t(strings.textNumberEmployee),
    type: "select",
    isValidate: true,
  },
  {
    name: "filePaymentFlow",
    placeholder: t(strings.textPlaceholderAddDocument),
    icon: <UploadIcon />,
    label: t(strings.textPaymentProcessFlow),
    type: "file",
    extensionFile: ".pdf",
    notes: t(strings.textInfoDocMax5MB),
    isOptional: true,
  },
  {
    name: "fileMerchantLogo",
    placeholder: t(strings.textPlaceholderCompanyLogo),
    icon: <UploadIcon />,
    label: t(strings.textMerchantLogo),
    type: "file",
    extensionFile: ".png",
    notes: t(strings.textInfoImageMax5MB),
    isOptional: true,
  },
  {
    name: "referralName",
    placeholder: t(strings.textPlaceholderReferralName),
    label: t(strings.textReferralName),
    type: "text",
    isValidate: true,
    isOptional: true,
  },

  {
    name: "citizenship",
    label: t(strings.textCitizenship),
    type: "radio",
    isValidate: true,
  },

  {
    name: "directorName",
    placeholder: t(strings.textPlaceholderDirectorName),
    label: t(strings.textDirectorName),
    type: "text",
    isValidate: true,
  },

  {
    name: "ktpNumberDirector",
    placeholder: t(strings.textPlaceholderIDNDirector),
    label: t(strings.textKTPDirector),
    type: "idCard",
    isValidate: true,
  },

  {
    name: "passportDirector",
    placeholder: t(strings.textPlaceholderPassportDirector),
    label: t(strings.textPassportDirector),
    type: "passport",
    isValidate: true,
  },

  {
    name: "guaratorName",
    placeholder: t(strings.textPlaceholderGuarantorName),
    label: t(strings.textGuarantorName),
    type: "text",
    isValidate: true,
  },
  {
    name: "idCardGuarantor",
    placeholder: t(strings.textPlaceholderKTPGuarantor),
    label: t(strings.textKTPGuarantor),
    type: "idCard",
    isValidate: true,
  },
  {
    name: "directorAddress",
    placeholder: `${t(
      strings.textExample
    )}: Jl. Raya Sejahtera No. 12, Kelapa Gading, Jakarta, DKI Jakarta 14230, Indonesia`,
    label: t(strings.textDirectorAddress),
    type: "text",
    isValidate: true,
  },
];

export default objectMerchantWNA;

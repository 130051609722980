import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import classes from "../RegistMerchant.module.css";
import ExpandMoreIcon from "../../General/Icon/ExpandMoreIcon";
import string from "../../General/Constant/Locales/Strings/RegistMerchantStrings";
import { listPayment } from "../../General/Helper/listPayment";
import { useTranslation } from "react-i18next";
import Buttons from "../../General/Component/Buttons";
import LeftArrowIcon from "../../General/Icon/LeftArrowIcon";
import RightIcon from "../../General/Icon/RightIcon";
import { useEffect, useState } from "react";
import getMerchantPreview from "../Helper/getMerchantPreview";
import { useNavigate } from "react-router-dom";
import DialogPopUp from "../../General/Component/DialogPopUp";
import { LoadingState } from "../../General/Component/LoadingComp";

const AccordionStyle = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ _theme }) => ({
  "& .MuiAccordion-root": {
    backgroundColor: "#fff",
    position: "none",
  },
  "& .MuiAccordionSummary-root": {
    backgroundColor: "#E8E5E6",
  },
  "& .MuiAccordionDetails-root": {
    padding: "10px 0 10px 0",
  },
}));

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: "#f5f8fa",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: "#ebf1f5",
    ...theme.applyStyles("dark", {
      backgroundColor: "#30404d",
    }),
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
    ...theme.applyStyles("dark", {
      background: "rgba(57,75,89,.5)",
    }),
  },
  ...theme.applyStyles("dark", {
    boxShadow: "0 0 0 1px rgb(16 22 26 / 40%)",
    backgroundColor: "#394b59",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))",
  }),
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{ "&:hover": { bgcolor: "transparent" } }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

const AccordionPreview = () => {
  const { t } = useTranslation("RegistMerchant");
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCheck = (event) => {
    setChecked(event.target.checked);
  };

  const handleDisabledButton = () => {
    return !checked;
  };

  useEffect(() => {
    getMerchantPreview(t, string, setData, setLoading);
  }, [t]);

  // const listAccordion = [
  //   {
  //     summary: t(string.textRegistrationInformation),
  //     pannel: "pannel1",
  //     expanded: true,
  //     details: [
  //       {
  //         section: 1,
  //         subtitle: "",
  //         subtext: "",
  //         list: [
  //           {
  //             label: t(string.textMerchantName),
  //             mandatory: true,
  //             value: "XYZ Company",
  //             disable: true,
  //             type: "text",
  //           },
  //           {
  //             label: t(string.textMerchantAddress),
  //             mandatory: true,
  //             value:
  //               "Jl. Raya Sejahtera No. 12, Kelapa Gading, Jakarta, DKI Jakarta 14230, Indonesia",
  //             disable: true,
  //             type: "text",
  //           },
  //           {
  //             label: t(string.textURLWeb),
  //             mandatory: true,
  //             value: "https://XYZCompany",
  //             disable: true,
  //             type: "text",
  //           },
  //           {
  //             label: t(string.textCompanyNPWP),
  //             mandatory: true,
  //             value: "1234567890123456",
  //             disable: true,
  //             type: "text",
  //           },
  //           {
  //             label: t(string.textCitizenship),
  //             mandatory: true,
  //             value: "Warga Negara Indonesia",
  //             disable: true,
  //             type: "text",
  //           },
  //           {
  //             label: t(string.textBusinessLength),
  //             mandatory: true,
  //             value: "2 Tahun",
  //             disable: true,
  //             type: "text",
  //           },
  //           {
  //             label: t(string.textNumberEmployee),
  //             mandatory: true,
  //             value: "0 - 100 Karyawan",
  //             disable: true,
  //             type: "text",
  //           },
  //           {
  //             label: t(string.textPaymentProcessFlow),
  //             mandatory: false,
  //             value: "Alur.PDF",
  //             disable: true,
  //             type: "file",
  //           },
  //           {
  //             label: t(string.textMerchantLogo),
  //             mandatory: false,
  //             value: "Logo.PNG",
  //             disable: true,
  //             type: "file",
  //           },
  //           {
  //             label: t(string.textMROName),
  //             mandatory: false,
  //             value: "Tony HR",
  //             disable: true,
  //             type: "text",
  //           },
  //         ],
  //       },
  //       {
  //         section: 2,
  //         subtitle: "",
  //         subtext: t(string.textDirectorInfo),
  //         list: [
  //           {
  //             label: t(string.textDirectorName),
  //             mandatory: true,
  //             value: "Tony HR",
  //             disable: true,
  //             type: "text",
  //           },
  //           {
  //             label: t(string.textIDDirector),
  //             mandatory: true,
  //             value: "1234567890123456",
  //             disable: true,
  //             type: "text",
  //           },
  //           {
  //             label: t(string.textDirectorAddress),
  //             mandatory: true,
  //             value:
  //               "Jl. Raya Sejahtera No. 12, Kelapa Gading, Jakarta, DKI Jakarta 14230, Indonesia",
  //             disable: true,
  //             type: "text",
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     summary: t(string.textPICInfo),
  //     pannel: "pannel2",
  //     expanded: false,
  //     details: [
  //       {
  //         section: 1,
  //         subtitle: `A. ${t(string.textPICName)}`,
  //         subtext: "",
  //         list: [
  //           {
  //             label: t(string.textEmployeeName),
  //             mandatory: true,
  //             value: "John Doe",
  //             disable: true,
  //             type: "text",
  //           },
  //           {
  //             label: t(string.textEmployeeTitle),
  //             mandatory: true,
  //             value: "Manager Bisnis",
  //             disable: true,
  //             type: "text",
  //           },
  //           {
  //             label: t(string.textPhoneNumber),
  //             mandatory: true,
  //             value: "81234567890123",
  //             disable: true,
  //             type: "text",
  //           },
  //           {
  //             label: t(string.textEmail),
  //             mandatory: true,
  //             value: "JohnDoe@gmail.com",
  //             disable: true,
  //             type: "text",
  //           },
  //         ],
  //       },
  //       {
  //         section: 2,
  //         subtitle: `B. ${t(string.textPICFinance)}`,
  //         subtext: "",
  //         list: [
  //           {
  //             label: t(string.textEmployeeName),
  //             mandatory: true,
  //             value: "John Doe",
  //             disable: true,
  //             type: "text",
  //           },
  //           {
  //             label: t(string.textEmployeeTitle),
  //             mandatory: true,
  //             value: "Manager Bisnis",
  //             disable: true,
  //             type: "text",
  //           },
  //           {
  //             label: t(string.textPhoneNumber),
  //             mandatory: true,
  //             value: "81234567890123",
  //             disable: true,
  //             type: "text",
  //           },
  //           {
  //             label: t(string.textEmail),
  //             mandatory: true,
  //             value: "JohnDoe@gmail.com",
  //             disable: true,
  //             type: "text",
  //           },
  //         ],
  //       },
  //       {
  //         section: 3,
  //         subtitle: `C. ${t(string.textPICtech)}`,
  //         subtext: "",
  //         list: [
  //           {
  //             label: t(string.textEmployeeName),
  //             mandatory: true,
  //             value: "John Doe",
  //             disable: true,
  //             type: "text",
  //           },
  //           {
  //             label: t(string.textEmployeeTitle),
  //             mandatory: true,
  //             value: "Manager Bisnis",
  //             disable: true,
  //             type: "text",
  //           },
  //           {
  //             label: t(string.textPhoneNumber),
  //             mandatory: true,
  //             value: "81234567890123",
  //             disable: true,
  //             type: "text",
  //           },
  //           {
  //             label: t(string.textEmail),
  //             mandatory: true,
  //             value: "JohnDoe@gmail.com",
  //             disable: true,
  //             type: "text",
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     summary: t(string.textBusinessInformation),
  //     pannel: "pannel3",
  //     expanded: false,
  //     details: [
  //       {
  //         section: 1,
  //         subtitle: "",
  //         subtext: "",
  //         list: [
  //           {
  //             label: t(string.textProductName),
  //             mandatory: true,
  //             value: "Banner",
  //             disable: true,
  //             type: "text",
  //           },
  //           {
  //             label: t(string.textMarketTarget),
  //             mandatory: true,
  //             value: "UMKM",
  //             disable: true,
  //             type: "text",
  //           },
  //           {
  //             label: t(string.textIndustryName),
  //             mandatory: true,
  //             value: "Creative",
  //             disable: true,
  //             type: "text",
  //           },
  //           {
  //             label: t(string.textCurrentMonthlySales),
  //             mandatory: true,
  //             value: "289000000",
  //             disable: true,
  //             type: "text",
  //           },
  //           {
  //             label: t(string.textAverageBilling),
  //             mandatory: true,
  //             value: "289000000",
  //             disable: true,
  //             type: "text",
  //           },
  //           {
  //             label: t(string.textMonthlySalesTurnover),
  //             mandatory: true,
  //             value: "289000000",
  //             disable: true,
  //             type: "text",
  //           },
  //         ],
  //       },
  //       {
  //         section: 2,
  //         subtitle: t(string.textBankAccountInformation),
  //         subtext: "",
  //         list: [
  //           {
  //             label: t(string.textBankName),
  //             mandatory: true,
  //             value: "BCA",
  //             disable: true,
  //             type: "text",
  //           },
  //           {
  //             label: t(string.textBankBranch),
  //             mandatory: true,
  //             value: "Benhil",
  //             disable: true,
  //             type: "text",
  //           },
  //           {
  //             label: t(string.textBankAccountName),
  //             mandatory: true,
  //             value: "Tony HR",
  //             disable: true,
  //             type: "text",
  //           },
  //           {
  //             label: t(string.textBankNumber),
  //             mandatory: true,
  //             value: "12334564",
  //             disable: true,
  //             type: "text",
  //           },
  //           {
  //             label: t(string.textCurrency),
  //             mandatory: true,
  //             value: "IDR",
  //             disable: true,
  //             type: "text",
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     summary: t(string.textServicesAndFees),
  //     pannel: "pannel4",
  //     expanded: false,
  //     details: [
  //       {
  //         section: 1,
  //         subtitle: "",
  //         subtext: "",
  //         list: [
  //           {
  //             label: t(string.textTypePaymentGateway),
  //             mandatory: true,
  //             value: "Integrasi",
  //             disable: true,
  //             type: "text",
  //           },
  //           {
  //             label: t(string.textIntegrationService),
  //             mandatory: true,
  //             value: "API Payment Gateway, API Payement Page",
  //             disable: true,
  //             type: "text",
  //           },
  //         ],
  //       },
  //       {
  //         section: 2,
  //         subtitle: t(string.textSelectedPaymentMethod),
  //         subtext: t(string.textCreditCard),
  //         list: [
  //           {
  //             label: "",
  //             mandatory: true,
  //             value: "Visa Master Card",
  //             disable: true,
  //             type: "img",
  //           },
  //         ],
  //       },
  //       {
  //         section: 3,
  //         subtitle: "",
  //         subtext: t(string.textVA),
  //         list: [
  //           {
  //             label: "",
  //             mandatory: true,
  //             value: "CIMB NIAGA",
  //             disable: true,
  //             type: "img",
  //           },
  //           {
  //             label: "",
  //             mandatory: true,
  //             value: "Bank BCA",
  //             disable: true,
  //             type: "img",
  //           },
  //           {
  //             label: "",
  //             mandatory: true,
  //             value: "Bank Mandiri",
  //             disable: true,
  //             type: "img",
  //           },
  //           {
  //             label: "",
  //             mandatory: true,
  //             value: "Bank BRI",
  //             disable: true,
  //             type: "img",
  //           },
  //           {
  //             label: "",
  //             mandatory: true,
  //             value: "Bank BNI",
  //             disable: true,
  //             type: "img",
  //           },
  //         ],
  //       },
  //       {
  //         section: 4,
  //         subtitle: "",
  //         subtext: t(string.textWallet),
  //         list: [
  //           {
  //             label: "",
  //             mandatory: true,
  //             value: "OVO",
  //             disable: true,
  //             type: "img",
  //           },
  //           {
  //             label: "",
  //             mandatory: true,
  //             value: "Shopee Pay",
  //             disable: true,
  //             type: "img",
  //           },
  //           {
  //             label: "",
  //             mandatory: true,
  //             value: "Link Aja!",
  //             disable: true,
  //             type: "img",
  //           },
  //           {
  //             label: "",
  //             mandatory: true,
  //             value: "Dana",
  //             disable: true,
  //             type: "img",
  //           },
  //           {
  //             label: "",
  //             mandatory: true,
  //             value: "Alipay",
  //             disable: true,
  //             type: "img",
  //           },
  //           {
  //             label: "",
  //             mandatory: true,
  //             value: "Virgo",
  //             disable: true,
  //             type: "img",
  //           },
  //         ],
  //       },
  //       {
  //         section: 5,
  //         subtitle: "",
  //         subtext: t(string.textOTC),
  //         list: [
  //           {
  //             label: "",
  //             mandatory: true,
  //             value: "Finpay",
  //             disable: true,
  //             type: "img",
  //           },
  //           {
  //             label: "",
  //             mandatory: true,
  //             value: "Alfamart by Finpay",
  //             disable: true,
  //             type: "img",
  //           },
  //           {
  //             label: "",
  //             mandatory: true,
  //             value: "Pegadaian by Finpay",
  //             disable: true,
  //             type: "img",
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     summary: t(string.textSupportingDocuments),
  //     pannel: "pannel5",
  //     expanded: false,
  //     details: [
  //       {
  //         section: 1,
  //         subtitle: "",
  //         subtext: "",
  //         list: [
  //           {
  //             label: t(string.textIDDirector),
  //             mandatory: true,
  //             value: "KTP_2134.PDF",
  //             disable: true,
  //             type: "file",
  //           },
  //           {
  //             label: t(string.textBankNumber),
  //             mandatory: true,
  //             value: "Tabungan.PDF",
  //             disable: true,
  //             type: "file",
  //           },
  //           {
  //             label: "NPWP",
  //             mandatory: true,
  //             value: "NPWP.PDF",
  //             disable: true,
  //             type: "file",
  //           },
  //           {
  //             label: t(string.textDeedEstablishmentAndAmendment),
  //             mandatory: true,
  //             value: "Akta_pendirian.PDF",
  //             disable: true,
  //             type: "file",
  //           },
  //           {
  //             label: t(string.textNIB),
  //             mandatory: true,
  //             value: "NIB.PDF",
  //             disable: true,
  //             type: "file",
  //           },
  //           {
  //             label: t(string.textMinistryLawAndHumanRightsDecree),
  //             mandatory: true,
  //             value: "SK_Kemenkuham.PDF",
  //             disable: true,
  //             type: "file",
  //           },
  //           {
  //             label: t(string.textTDPSE),
  //             mandatory: false,
  //             value: "PSE.PDF",
  //             disable: true,
  //             type: "file",
  //           },
  //           {
  //             label: t(string.textInstitutionLicence),
  //             mandatory: true,
  //             value: "IZIN_Lembanga.PDF",
  //             disable: true,
  //             type: "file",
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // ];

  return (
    <>
      <DialogPopUp
        open={openConfirm}
        maxWidth={"sm"}
        onClose={() => setOpenConfirm(false)}
        title={t(string.textWantContinueProcess)}
        content={
          <>
            <Typography className={classes.content_confirm}>
              {t(string.textMakeSureCorrect)}
            </Typography>
            <div className={classes.divGroupButton}>
              <Buttons
                loadingSubmit={false}
                label={t(string.textNoCheckAgain)}
                handleDisabledButton={() => false}
                onSubmitData={() => navigate("/merchant_registration_form")}
                leftIcon={null}
                isSolid={false}
              />
              <Buttons
                loadingSubmit={false}
                label={t(string.textYesContinue)}
                handleDisabledButton={() => false}
                onSubmitData={() => setOpenConfirm(true)}
                rightIcon={null}
              />
            </div>
          </>
        }
        actions={null}
      />
      <Typography className={classes.textPreviewData}>
        {t(string.textPreviewData)}
      </Typography>
      {loading ? (
        <>
          <LoadingState />
        </>
      ) : (
        <>
          <div>
            {data?.map((item) => (
              <>
                <AccordionStyle defaultExpanded={item.expanded}>
                  <AccordionSummary
                    className={classes.Accor}
                    expandIcon={<ExpandMoreIcon />}
                    id={item.pannel}
                  >
                    <Typography className={classes.summary_text}>
                      {item.summary}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {item.details?.map((el) => (
                      <>
                        <div className={classes.section_preview}>
                          {el.subtitle !== "" ? (
                            <Typography
                              className={classes.subtitle_detail_preview}
                            >
                              {el.subtitle}
                            </Typography>
                          ) : null}
                          {el.subtext !== "" ? (
                            <Typography
                              className={classes.subtext_detail_preview}
                            >
                              {el.subtext}
                            </Typography>
                          ) : null}
                          <div className={classes.section_value}>
                            {el.list?.map((e) => (
                              <>
                                {e.type === "img" ? (
                                  <>
                                    <div className={classes.div_pannel_4}>
                                      <div className={classes.div_img_value}>
                                        {listPayment.map((val) => (
                                          <>
                                            {val.channelName === e.value ? (
                                              <>
                                                <img
                                                  src={val.logo}
                                                  alt="logo"
                                                  width="50px"
                                                />
                                              </>
                                            ) : null}
                                          </>
                                        ))}
                                        <Typography
                                          className={classes.value_payment}
                                        >
                                          {e.value}
                                        </Typography>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <div className={classes.div_label_value}>
                                    <div className={classes.div_label_opti}>
                                      {e.label !== "" ? (
                                        <Typography
                                          className={classes.label_preview}
                                        >
                                          {e.label}
                                        </Typography>
                                      ) : null}
                                      {e.mandatory !== true ? (
                                        <Typography
                                          className={classes.text_opti_label}
                                        >
                                          Optional
                                        </Typography>
                                      ) : null}
                                    </div>

                                    {e.type === "file" ? (
                                      <>
                                        <div
                                          className={classes.value_file_field}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                          >
                                            <path
                                              d="M20.0306 6.21938L16.2806 2.46938C16.2109 2.39975 16.1282 2.34454 16.0371 2.3069C15.9461 2.26926 15.8485 2.24992 15.75 2.25H8.25C7.85218 2.25 7.47064 2.40804 7.18934 2.68934C6.90804 2.97064 6.75 3.35218 6.75 3.75V5.25H5.25C4.85218 5.25 4.47064 5.40804 4.18934 5.68934C3.90804 5.97064 3.75 6.35218 3.75 6.75V20.25C3.75 20.6478 3.90804 21.0294 4.18934 21.3107C4.47064 21.592 4.85218 21.75 5.25 21.75H15.75C16.1478 21.75 16.5294 21.592 16.8107 21.3107C17.092 21.0294 17.25 20.6478 17.25 20.25V18.75H18.75C19.1478 18.75 19.5294 18.592 19.8107 18.3107C20.092 18.0294 20.25 17.6478 20.25 17.25V6.75C20.2501 6.65148 20.2307 6.55391 20.1931 6.46286C20.1555 6.37182 20.1003 6.28908 20.0306 6.21938ZM15.75 20.25H5.25V6.75H12.4397L15.75 10.0603V17.985C15.75 17.9906 15.75 17.9953 15.75 18C15.75 18.0047 15.75 18.0094 15.75 18.015V20.25ZM18.75 17.25H17.25V9.75C17.2501 9.65148 17.2307 9.55391 17.1931 9.46286C17.1555 9.37182 17.1003 9.28908 17.0306 9.21937L13.2806 5.46938C13.2109 5.39975 13.1282 5.34454 13.0371 5.3069C12.9461 5.26926 12.8485 5.24992 12.75 5.25H8.25V3.75H15.4397L18.75 7.06031V17.25ZM13.5 14.25C13.5 14.4489 13.421 14.6397 13.2803 14.7803C13.1397 14.921 12.9489 15 12.75 15H8.25C8.05109 15 7.86032 14.921 7.71967 14.7803C7.57902 14.6397 7.5 14.4489 7.5 14.25C7.5 14.0511 7.57902 13.8603 7.71967 13.7197C7.86032 13.579 8.05109 13.5 8.25 13.5H12.75C12.9489 13.5 13.1397 13.579 13.2803 13.7197C13.421 13.8603 13.5 14.0511 13.5 14.25ZM13.5 17.25C13.5 17.4489 13.421 17.6397 13.2803 17.7803C13.1397 17.921 12.9489 18 12.75 18H8.25C8.05109 18 7.86032 17.921 7.71967 17.7803C7.57902 17.6397 7.5 17.4489 7.5 17.25C7.5 17.0511 7.57902 16.8603 7.71967 16.7197C7.86032 16.579 8.05109 16.5 8.25 16.5H12.75C12.9489 16.5 13.1397 16.579 13.2803 16.7197C13.421 16.8603 13.5 17.0511 13.5 17.25Z"
                                              fill="#1677FF"
                                            />
                                          </svg>
                                          <Typography
                                            className={classes.value_file_text}
                                          >
                                            {e.value}
                                          </Typography>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <TextField
                                          disabled
                                          fullWidth
                                          defaultValue={e.value}
                                          sx={{
                                            "& .MuiOutlinedInput-root.Mui-disabled":
                                              {
                                                "& fieldset": {
                                                  borderRadius: "8px",
                                                  border: "1px solid #CFDFEC",
                                                },
                                              },
                                            "& .MuiOutlinedInput-input.Mui-disabled":
                                              {
                                                borderRadius: "8px",
                                                height: "10px",
                                                WebkitTextFillColor: "#231F20",
                                                background: "#F6F4F5",
                                              },
                                          }}
                                        />
                                      </>
                                    )}
                                  </div>
                                )}
                              </>
                            ))}
                          </div>
                        </div>
                      </>
                    ))}
                    <div className={classes.line_bottom}></div>
                  </AccordionDetails>
                </AccordionStyle>
              </>
            ))}
            <div className={classes.div_check_confirm}>
              <BpCheckbox checked={checked} onChange={handleCheck} />
              <Typography className={classes.label_confirmation}>
                {t(string.textLabelConfirmationTnc)}
              </Typography>
            </div>

            <div className={classes.divGroupButton}>
              <Buttons
                loadingSubmit={false}
                label={t(string.textPrevious)}
                handleDisabledButton={() => false}
                onSubmitData={() => navigate("/merchant_registration_form")}
                leftIcon={<LeftArrowIcon />}
                isSolid={false}
              />
              <Buttons
                loadingSubmit={false}
                label={t(string.textNext)}
                handleDisabledButton={handleDisabledButton}
                onSubmitData={() => setOpenConfirm(true)}
                rightIcon={<RightIcon />}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AccordionPreview;

import { useTranslation } from "react-i18next";
import SnackbarNotification from "../../General/Component/SnackbarNotification";
import useSnackbar from "../../General/Helper/useSnackbar";
import objectBusinessInfo from "../Constant/objectBusinessInfo";
import { Typography } from "@mui/material";
import Textfields from "../../General/Component/Textfields";
import strings from "../../General/Constant/Locales/Strings/RegistMerchantStrings";
import classes from "../RegistMerchant.module.css";
import Buttons from "../../General/Component/Buttons";
import RightIcon from "../../General/Icon/RightIcon";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import AutoCompleteSelect from "../../General/Component/AutocompleteSelect";
import objectBankAccount from "../Constant/objectBankAccount";
import RadioButtons from "../../General/Component/RadioButtons";
import currencyList from "../Constant/currencyList";
import formatNumber from "../../General/Helper/formatNumber";
import { AmountSchema } from "../../General/Helper/validationField";
import onSubmitMerchantInfo from "../Helper/onSubmitMerchantInfo";
import getMerchantDetail from "../Helper/getMerchantDetail";
import LeftArrow from "../../General/Icon/LeftArrow";
import { renderObjectBank } from "../Helper/renderBankInfo";
import {
  isValidAmount,
  isValidName,
  isValidNumber,
} from "../../General/Helper/handleValidation";

const FormBusinessInfo = ({
  activeSectionNumber,
  setActiveSectionNumber,
  activeStepOutlined,
  setActiveStepOutlined,
  progressData,
  setProgressData,
}) => {
  const { t } = useTranslation("RegistMerchant");
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();
  const userDataLogin = useSelector((state) => state.user_data.userDataLogin);
  const [formData, setFormData] = useState({
    productName: "",
    marketTargetName: "",
    industryName: "",
    monthlySalesAmount: "",
    companyTaxType: "",
    averageBillAmount: "",
    monthlySalesTurnoverAmount: "",
    bankName: "",
    bankSwiftNumber: "",
    bankBranchName: "",
    bankNumber: "",
    bankAccountName: "",
    currency: "",
    loadingSubmit: false,
  });
  const [merchantDetail, setMerchantDetail] = useState(null);

  useEffect(() => {
    getMerchantDetail(
      t,
      strings,
      activeSectionNumber,
      activeStepOutlined,
      setMerchantDetail,
      showSnackbar
    );
  }, [activeSectionNumber, activeStepOutlined, showSnackbar, t]);

  useEffect(() => {
    if (merchantDetail) {
      const {
        merchantProduct,
        targetMarket,
        taxType,
        industry,
        currentMonthlySales,
        estimatedAverageBillingAccount,
        estimatedMonthlySalesTurnover,
        merchantBank,
      } = merchantDetail;

      setFormData((prev) => ({
        ...prev,
        productName: merchantProduct,
        marketTargetName: targetMarket,
        industryName: industry,
        companyTaxType: taxType,
        monthlySalesAmount: currentMonthlySales
          ? formatNumber(String(currentMonthlySales).replace(/\./g, ""))
          : "",
        averageBillAmount: estimatedAverageBillingAccount
          ? formatNumber(
              String(estimatedAverageBillingAccount).replace(/\./g, "")
            )
          : "",
        monthlySalesTurnoverAmount: estimatedMonthlySalesTurnover
          ? formatNumber(
              String(estimatedMonthlySalesTurnover).replace(/\./g, "") || ""
            )
          : "",
        bankName: merchantBank?.bank,
        bankSwiftNumber: merchantBank?.swiftCode,
        bankNumber: merchantBank?.accountNumber,
        bankBranchName: merchantBank?.branch,
        bankAccountName: merchantBank?.accountName,
        currency: merchantBank?.accountCurrency,
      }));
    }
  }, [merchantDetail]);

  const handleChange = (e, name) => {
    const { target } = e;

    if (target) {
      const { name, value } = target;
      const updatedValue = name.toLowerCase().includes("amount")
        ? formatNumber(value.replace(/\./g, ""))
        : value;

      setFormData((prev) => ({ ...prev, [name]: updatedValue }));
    } else if (name) {
      setFormData((prev) => ({ ...prev, [name]: e }));
    }
  };

  useEffect(() => {
    calculateProgressValue();
  }, [formData]);

  const calculateProgressValue = () => {
    const progressConditions = [
      formData.productName,
      formData.marketTargetName,
      formData.industryName,
      formData.monthlySalesAmount,
      formData.monthlySalesTurnoverAmount,
      formData.averageBillAmount,
      formData.bankName,
      formData.bankBranchName || formData.bankSwiftNumber,
      formData.bankNumber,
      formData.bankAccountName,
      formData.currency,
    ];

    const validProgressCount = progressConditions.filter(Boolean).length;
    const progressNumber =
      (validProgressCount / progressConditions.length) * 20;

    setProgressData((prev) => ({
      ...prev,
      section3: Math.ceil(progressNumber),
    }));
  };

  const handlePreviousStep = () => {
    setActiveSectionNumber((prev) => prev - 1);
  };

  const handleDisabledButton = () => {
    const {
      loadingSubmit,
      productName,
      marketTargetName,
      industryName,
      companyTaxType,
      monthlySalesAmount,
      averageBillAmount,
      monthlySalesTurnoverAmount,
      bankName,
      bankBranchName,
      bankSwiftNumber,
      bankNumber,
      bankAccountName,
      currency,
    } = formData;

    const isFormIncomplete =
      loadingSubmit ||
      !productName ||
      !isValidName(productName) ||
      !marketTargetName ||
      !isValidName(marketTargetName) ||
      !industryName ||
      !companyTaxType ||
      !monthlySalesAmount ||
      !isValidAmount(monthlySalesAmount) ||
      !averageBillAmount ||
      !isValidAmount(averageBillAmount) ||
      !monthlySalesTurnoverAmount ||
      !isValidAmount(monthlySalesTurnoverAmount) ||
      !currency ||
      !bankName ||
      !isValidName(bankName) ||
      !bankAccountName ||
      !isValidName(bankAccountName) ||
      !bankNumber ||
      !isValidNumber(bankNumber);

    const isFormIDR = !bankBranchName || !isValidName(bankBranchName);
    const isFormUSD = !bankSwiftNumber || !isValidNumber(bankSwiftNumber);

    if (currency === "IDR") {
      return isFormIncomplete || isFormIDR;
    } else {
      return isFormIncomplete || isFormUSD;
    }
  };

  const onSubmitBusinessInfo = () => {
    const payload = {
      businessType: userDataLogin?.business_type,
      merchantProduct: formData.productName,
      targetMarket: formData.marketTargetName,
      industry: formData.industryName,
      taxType: formData.companyTaxType,

      currentMonthlySales: Number(
        formData.monthlySalesAmount.replace(/\./g, "")
      ),
      estimatedAverageBillingAccount: Number(
        formData.averageBillAmount.replace(/\./g, "")
      ),
      estimatedMonthlySalesTurnover: Number(
        formData.monthlySalesTurnoverAmount.replace(/\./g, "")
      ),
      merchantBank: {
        bank: formData.bankName,
        branch: formData.bankBranchName,
        swiftCode: formData.bankSwiftNumber,
        accountName: formData.bankAccountName,
        accountNumber: formData.bankNumber,
        accountCurrency: formData.currency,
      },
      section: activeSectionNumber + 1,
      businessCompletion: progressData.section3,
      totalCompletion: 20,
    };
    onSubmitMerchantInfo(
      t,
      strings,
      payload,
      activeSectionNumber,
      setActiveSectionNumber,
      activeStepOutlined,
      setActiveStepOutlined,
      setFormData,
      showSnackbar
    );
  };

  return (
    <>
      <SnackbarNotification snackbar={snackbar} hideSnackbar={hideSnackbar} />

      <div className={classes.divFormStepper}>
        {objectBusinessInfo(t, strings).map((field, index) => {
          switch (field.name) {
            case "industryName":
            case "companyTaxType":
              return (
                <AutoCompleteSelect
                  options={field.options}
                  value={formData[field.name]}
                  name={field.name}
                  label={field.label}
                  key={index}
                  onChange={(e) => handleChange(e, field.name)}
                  placeholder={field.placeholder}
                />
              );

            default:
              return (
                <Textfields
                  key={index}
                  isHideLabel={true}
                  placeholder={field.placeholder}
                  label={field.label}
                  type={field.type}
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleChange}
                  isValidate={field.isValidate}
                  isOptional={field.isOptional}
                  fullWidth
                />
              );
          }
        })}

        <div className={classes.divBoundaries}>
          <Typography className={classes.textSuperBold18}>
            {t(strings.textBankAccountInformation)}
          </Typography>
          <Typography className={classes.textRegularDarkGray14}>
            {t(strings.textProvideBankInfo)}
          </Typography>
        </div>

        <div className={classes.divLine} />
        {objectBankAccount(t, strings)
          .slice(0, 1)
          .map((field, index) => (
            <RadioButtons
              label={field.label}
              name={field.name}
              value={formData[field.name]}
              onChange={handleChange}
              options={currencyList()}
              row={true}
              key={index}
            />
          ))}

        <div className={classes.divWrapFourColumn}>
          {objectBankAccount(t, strings)
            .slice(1, objectBankAccount(t, strings).length)
            .reduce((rows, field, index) => {
              if (index % 2 === 0) {
                rows.push([field]);
              } else {
                rows[rows.length - 1].push(field);
              }
              return rows;
            }, [])
            .map((row) => {
              return row.map((field, index) => {
                return renderObjectBank(
                  classes,
                  field,
                  index,
                  formData,
                  handleChange
                );
              });
            })}
        </div>

        <div className={classes.divGroupButton}>
          <Buttons
            loadingSubmit={false}
            label={t(strings.textPrevious)}
            handleDisabledButton={() => false}
            onSubmitData={handlePreviousStep}
            leftIcon={<LeftArrow />}
            isSolid={false}
          />
          <Buttons
            loadingSubmit={formData.loadingSubmit}
            label={t(strings.textNext)}
            handleDisabledButton={handleDisabledButton}
            onSubmitData={onSubmitBusinessInfo}
            rightIcon={<RightIcon />}
          />
        </div>
      </div>
    </>
  );
};

export default FormBusinessInfo;

import { Typography } from "@mui/material";
import classes from "../Style/general.module.css";

export function SuccessInformation(props) {
  const { header, text } = props;
  return (
    <div className={classes.boxSuccessState}>
      <img
        src="https://res.cloudinary.com/pastisah-id/image/upload/v1723798662/digital-product-logo/web-illustration/SuccessIllustration_dxofod.png"
        className={classes.imageSuccess}
        alt=""
      />
      <div>
        <Typography className={classes.textBold600H1}>{header}</Typography>
        <Typography className={classes.textRegularDarkGray}>{text}</Typography>
      </div>
    </div>
  );
}

const objectPaymentService = (t, strings) => [
  {
    name: "serviceCategory",
    placeholder: t(strings.textPlaceholderPaymentType),
    label: t(strings.textSelectPaymentService),
    type: "text",
    isValidate: true,
  },
  {
    name: "serviceDetails",
    placeholder: t(strings.textPlaceholderPaymentType),
    label: t(strings.textSelectPaymentService),
    type: "text",
    isValidate: true,
  },
  {
    name: "channels",
    placeholder: t(strings.textPlaceholderPaymentType),
    label: t(strings.textSelectPaymentService),
    type: "text",
    isValidate: true,
  },
  {
    name: "disbursementServiceDetails",
    placeholder: t(strings.textPlaceholderPaymentType),
    label: t(strings.textSelectPaymentService),
    type: "text",
    isValidate: true,
  },
];

export default objectPaymentService;

const strings = {
  textRegisterPreparation:
    "For a smooth registration process, prepare the following documents!",
  textDirectorEKTP: "Director KTP",
  textAccountNumber: "Account Number",
  textCompanyNPWP: "Company NPWP",
  textPersonalNPWP: "Personal NPWP",
  textDeedEstablishmentAndAmendment: "Deed of Establishment & Amendment",
  textMinistryLawAndHumanRightsDecree:
    "Ministry of Law and Human Rights Decree",
  textTDPSE: "Electronic System Operator Registration Certificate (TD PSE)",
  textNIB: "Business Identification Number (NIB)",
  textInstitutionLicence: "Institution Licence",
  textContinueProcess: "Continue the Process",
  textOptional: "Optional",
  textLogout: "Log out",
  textMerchantRegistration: "Merchant Registration",
  textVerificationProcess: "Verification Process",
  textActivationStatus: "Activation Status",
  textWebStaging: "Web Staging",
  textRegistrationInformation: "Registration Information",
  textPICName: "Name of Person in Charge",
  textBusinessInformation: "Business Information",
  textServicesAndFees: "Services & Fees",
  textCitizenship: "Citizenship",
  textMerchantName: "Merchant Name",
  textCompanyName: "Company Name",
  textURLWeb: "Website URL",
  textKTPDirector: "Director's KTP",
  textMerchantAddress: "Merchant Address",
  textBusinessLength: "Business Length of Time",
  textNumberEmployee: "Number of Employees",
  textPaymentProcessFlow: "Payment Process Flow",
  textMerchantLogo: "Merchant Logo",
  textIndonesianCitizen: "Indonesian Citizen",
  textForeignCitizen: "Foreign Citizen",
  textEmployees: "employees",
  textExample: "Example",
  textInfoDocMax5MB:
    "Make sure the document you upload is in .PDF format and the maximum file size is 5MB.",
  textInfoImageMax5MB:
    "Make sure the logo you upload is PNG format and the maximum file size is 5MB.",
  textNext: "Next",
  textPassportDirector: "Director Passport/KITAS Number",
  textPICBusinessName: "Name of Business Person in Charge",
  textPICTechnicalName: "Name of Technical Person in Charge",
  textEmployeeName: "Employee Name",
  textEmployeeTitle: "Employee Title",
  textEmployeePhoneNumber: "Employee Phone Number",
  textEmployeeEmail: "Employee Email",
  textPrevious: "Previous",
  textProductName: "Products/Services Marketed",
  textMarketTarget: "Target Market",
  textIndustryName: "Industry",
  textCurrentMonthlySales: "Current Monthly Sales",
  textAverageBilling: "Average Estimated Bill Amount",
  textMonthlySalesTurnover: "Estimated Monthly Sales Turnover",
  textBankAccountInformation: "Bank Account Information",
  textProvideBankInfo:
    "Please provide the bank account information that will be used to receive transactions.",
  textAccountantServices: "Accountant Services",
  textBankingAndFinances: "Banking & Finance",
  textAdminAndHRD: "Admin & HRD",
  textBeautyHealthCare: "Beauty/Health Care",
  textBuildingConstructionOrMechanical: "Building Construction/Mechanical",
  textDesign: "Design",
  textEducation: "Education",
  textSecurityServices: "Security Services",
  textCleaningServices: "Cleaning Services",
  textHospitalityOrTourismServices: "Hospitality/Tourism Services",
  textFoodOrBeverage: "Food/Beverage",
  textInformationTechnology: "Information Technology",
  textInsurance: "Insurance",
  textManufacturing: "Manufacturing",
  textMarketing: "Marketing",
  textMediaOrAdvertising: "Media/Advertising",
  textMedicalOrHealthcare: "Medical/Healthcare",
  textTelecommunications: "Telecommunications",
  textTransportAndLogistics: "Transport & Logistics",
  textBroker: "Broker",
  textDistributor: "Distributor",
  textConsultant: "Consultant",
  textOthers: "Others",
  textBankName: "Bank Name",
  textBankBranch: "Bank Branch",
  textBankNumber: "Bank Account Number",
  textBankAccountName: "Bank Account Name",
  textCurrency: "Currency",
  textProgressScore: "Progress Score",
  textMROName: "Name of Merchant Relations Officer",
  textDirectorInfo: "Director Information",
  textIDDirector: "Director's ID Number",
  textPICInfo: "Person in Charge Information",
  textPhoneNumber: "Phone Number",
  textEmail: "Email",
  textPICtech: "Name of Person in Charge of Technical",
  textTypePaymentGateway: "Types of Payment Gateway Services",
  textIntegrationService: "Integration Services",
  textSelectedPaymentMethod: "Selected Payment Method",
  textCreditCard: "Credit Card",
  textVA: "Virtual Account",
  textWallet: "e-Money/e-Wallet",
  textLabelConfirmationTnc:
    "I hereby declare that I as a merchant agree to all forms of terms and conditions of service that have been determined by iFortepay.",
  textSelectPaymentService: "Select Payment Gateway Service Type",
  textAPIIntegration: "API Integration",
  textNonIntegration: "Non Integration",
  textDisbursementOnly: "Disbursement Only",
  textSelectIntegrationService: "Select Integration Service",
  textAPIPaymentGateway: "API Payment Gateway",
  textAPIPaymentPage: "API Payment Page",
  textSelectPaymentChannel: "Select Payment Channel",
  textSelectPaymentChannelSubtitle:
    "Select the type of payment channel you wish to use.",
  textIncludeVAT: "(Include PPN) per transaction",
  textVirtualCard: "Virtual Card",
  textOTC: "Over The Counter (OTC)",
  textMustHaveBCAVirtualAccount:
    "*For the BCA VA Channel, you must have a BCA account.",
  textAskForDisbursement: "Do you want to use the disbursement service?",
  textSelectDisbursementService: "Select Disbursement Service",
  textReferralName: "Referral Name",
  textDirectorInformation: "Director Information",
  textCompleteDirectorInfo:
    "Please complete the information about the Director.",
  textDirectorName: "Director Name",
  textDirectorAddress: "Director Address",
  textGuarantorName: "Name of PIC/Guarantor",
  textKTPGuarantor: "PIC/Guarantor KTP number",
  textPICFinance: "Name of Person in Charge of Finance/Accountant",
  textCompanyTaxType: "Company Tax Type",
  textTaxableEntrepreneur: "Taxable Entrepreneur (PKP)",
  textNonTaxableEntrepreneur: "Non-Taxable Entrepreneur (Non-Taxable)",
  textBankSwiftCode: "Bank Swift Code",
  textWantContinueProcess: "Want to continue the process?",
  textMakeSureCorrect:
    "Before you continue the registration process, make sure all data is correct.",
  textNoCheckAgain: "No, Please Check Again",
  textYesContinue: "Yes, Continue Process",
  textPreviewData: "Preview Data",
  textSupportingDocuments: "Supporting Documents",
  textSubtitleSupportingDocs:
    "Upload some supporting documents to continue the registration process.",
  textInfoSupportingDoc:
    "Make sure all documents are in PDF format with a file size not exceeding 5MB.",
  textInstitutionPermit: "Institution Permit",

  // PLACEHOLDER
  textPlaceholderMerchantName: "Enter merchant name",
  textPlaceholderWebsiteURL: "Enter website URL",
  textPlaceholderCompanyNPWP: "Enter company NPWP",
  textPlaceholderIDNDirector: "Enter the company director's KTP",
  textPlaceholderBusinessPeriod: "Enter the time period of business",
  textPlaceholderChooseEmployeeNumber: "Choose the number of employees",
  textPlaceholderAddDocument: "Add document",
  textPlaceholderCompanyLogo: "Add company logo",
  textPlaceholderPassportDirector: "Enter the director's passport/KITAS number",
  textPlaceholderEmployeeName: "Enter employee name",
  textPlaceholderEmployeeTitle: "Enter employee title",
  textPlaceholderPhoneNumber: "8xxxxxxx",
  textPlaceholderEmployeeEmail: "Enter employee email",
  textPlaceholderProductName: "Example: Digital Product",
  textPlaceholderMarketTarget: "Company's target market",
  textPlaceholderIndustryName: "Select your company's industry",
  textPlaceholderCurrentMonthlySales: "Enter your current monthly sales",
  textPlaceholderAverageBilling: "Enter your average bill amount",
  textPlaceholderMonthlySalesTurnover: "Enter your monthly sales turnover",
  textPlaceholderBankName: "Enter the bank name",
  textPlaceholderBankBranch: "Enter the branch of your bank",
  textPlaceholderBankNumber: "Enter your bank account number",
  textPlaceholderBankAccountName: "Enter your account number",
  textPlaceholderPaymentType: "Select the service to use",
  textPlaceholderReferralName: "Enter your referral name",
  textPlaceholderDirectorName: "Enter the director name",
  textPlaceholderGuarantorName: "Enter the PIC/Guarantor name",
  textPlaceholderKTPGuarantor: "Enter the PIC KTP number",
  textPlaceholdertextCompanyTaxType: "Select the company tax type",
  textPlaceholderBankSwiftCode: "Enter bank swift code",
};

export default strings;

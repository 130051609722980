import classes from "../RegistMerchant.module.css";
import SnackbarNotification from "../../General/Component/SnackbarNotification";
import useSnackbar from "../../General/Helper/useSnackbar";
import { SuccessInformation } from "../../General/Component/SuccessInformation";

export function VerificationStatus(props) {
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();

  return (
    <>
      <SnackbarNotification snackbar={snackbar} hideSnackbar={hideSnackbar} />

      <div className={classes.divFormStepper}>
        <SuccessInformation
          header={"Proses verifikasi data anda berhasil"}
          text={
            "Registrasi Anda telah berhasil. Silakan lihat informasi dukungan dan biaya pembayaran yang telah Anda pilih. Jika terdapat kesalahan informasi, hubungi Tim Support kami."
          }
        />
      </div>
    </>
  );
}

import { useTranslation } from "react-i18next";
import SnackbarNotification from "../../General/Component/SnackbarNotification";
import useSnackbar from "../../General/Helper/useSnackbar";
import { useEffect, useState } from "react";
import Textfields from "../../General/Component/Textfields";
import objectPICInfo from "../Constant/objectPICInfo";
import strings from "../../General/Constant/Locales/Strings/RegistMerchantStrings";
import classes from "../RegistMerchant.module.css";
import { Typography } from "@mui/material";
import Buttons from "../../General/Component/Buttons";
import {
  EmailSchema,
  PhoneNumberSchema,
  RegularSchema,
} from "../../General/Helper/validationField";
import RightIcon from "../../General/Icon/RightIcon";
import onSubmitMerchantInfo from "../Helper/onSubmitMerchantInfo";
import { useSelector } from "react-redux";
import getMerchantDetail from "../Helper/getMerchantDetail";
import LeftArrow from "../../General/Icon/LeftArrow";
import {
  isValidEmail,
  isValidName,
  isValidPhone,
  isValidTitlePerson,
} from "../../General/Helper/handleValidation";

const FormPIC = ({
  activeSectionNumber,
  setActiveSectionNumber,
  activeStepOutlined,
  setActiveStepOutlined,
  progressData,
  setProgressData,
}) => {
  const { t } = useTranslation("RegistMerchant");
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();
  const userDataLogin = useSelector((state) => state.user_data.userDataLogin);
  const [formData, setFormData] = useState({
    picBusinessName: "",
    picBusinessTitle: "",
    picBusinessPhoneNumber: "",
    picBusinessEmail: "",
    picFinanceName: "",
    picFinanceTitle: "",
    picFinancePhoneNumber: "",
    picFinanceEmail: "",
    picTechnicalName: "",
    picTechnicalTitle: "",
    picTechnicalPhoneNumber: "",
    picTechnicalEmail: "",
  });

  const [merchantDetail, setMerchantDetail] = useState(null);

  useEffect(() => {
    getMerchantDetail(
      t,
      strings,
      activeSectionNumber,
      activeStepOutlined,
      setMerchantDetail,
      showSnackbar
    );
  }, [activeSectionNumber, activeStepOutlined, showSnackbar, t]);

  useEffect(() => {
    if (merchantDetail) {
      const merchantPersonInCharges = merchantDetail.merchantPersonInCharges;
      const businessData = merchantPersonInCharges.find(
        (data) => data.picType === "Business"
      );
      const technicalData = merchantPersonInCharges.find(
        (data) => data.picType === "Technical"
      );

      const financeData = merchantPersonInCharges.find(
        (data) => data.picType === "Finance"
      );

      setFormData((prev) => ({
        ...prev,
        picBusinessName: businessData?.picName,
        picBusinessTitle: businessData?.position,
        picBusinessPhoneNumber: businessData?.phone?.replace("+62", ""),
        picBusinessEmail: businessData?.email,
        picFinanceName: financeData?.picName,
        picFinanceTitle: financeData?.position,
        picFinancePhoneNumber: financeData?.phone?.replace("+62", ""),
        picFinanceEmail: financeData?.email,
        picTechnicalName: technicalData?.picName,
        picTechnicalTitle: technicalData?.position,
        picTechnicalPhoneNumber: technicalData?.phone?.replace("+62", ""),
        picTechnicalEmail: technicalData?.email,
      }));
    }
  }, [merchantDetail]);

  const handleChange = async (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    calculateProgressValue();
  }, [formData]);

  const calculateProgressValue = () => {
    const progressConditions = [
      formData.picBusinessName,
      formData.picBusinessTitle,
      formData.picBusinessPhoneNumber,
      formData.picBusinessEmail,
      formData.picFinanceName,
      formData.picFinanceTitle,
      formData.picFinancePhoneNumber,
      formData.picFinanceEmail,
      formData.picTechnicalName,
      formData.picTechnicalTitle,
      formData.picTechnicalPhoneNumber,
      formData.picTechnicalEmail,
    ];

    const validProgressCount = progressConditions.filter(Boolean).length;
    const progressNumber =
      (validProgressCount / progressConditions.length) * 20;

    setProgressData((prev) => ({
      ...prev,
      section2: Math.ceil(progressNumber),
    }));
  };

  const handlePreviousStep = () => {
    setActiveSectionNumber((prev) => prev - 1);
  };

  const handleDisabledButton = () => {
    return (
      formData.loadingSubmit ||
      !formData.picBusinessName ||
      !isValidName(formData.picBusinessName) ||
      !formData.picBusinessTitle ||
      !isValidTitlePerson(formData.picBusinessTitle) ||
      !formData.picBusinessPhoneNumber ||
      !isValidPhone(formData.picBusinessPhoneNumber) ||
      !formData.picBusinessEmail ||
      !isValidEmail(formData.picBusinessEmail) ||
      !formData.picTechnicalName ||
      !isValidName(formData.picTechnicalName) ||
      !formData.picTechnicalTitle ||
      !isValidTitlePerson(formData.picTechnicalTitle) ||
      !formData.picTechnicalPhoneNumber ||
      !isValidPhone(formData.picTechnicalPhoneNumber) ||
      !formData.picTechnicalEmail ||
      !isValidEmail(formData.picTechnicalEmail) ||
      !formData.picFinanceName ||
      !isValidName(formData.picFinanceName) ||
      !formData.picFinanceTitle ||
      !isValidTitlePerson(formData.picFinanceTitle) ||
      !formData.picFinancePhoneNumber ||
      !isValidPhone(formData.picFinancePhoneNumber) ||
      !formData.picFinanceEmail ||
      !isValidEmail(formData.picFinanceEmail)
    );
  };

  const onSubmitPICInfo = () => {
    const payload = {
      businessType: userDataLogin?.business_type,
      merchantBusinessPic: {
        picType: "Business",
        picName: formData?.picBusinessName,
        position: formData?.picBusinessTitle,
        phone: `+62` + formData?.picBusinessPhoneNumber,
        email: formData?.picBusinessEmail,
      },
      merchantTechnicalPic: {
        picType: "Technical",
        picName: formData?.picTechnicalName,
        position: formData?.picTechnicalTitle,
        phone: `+62` + formData?.picTechnicalPhoneNumber,
        email: formData?.picTechnicalEmail,
      },
      merchantFinancePic: {
        picType: "Finance",
        picName: formData?.picFinanceName,
        position: formData?.picFinanceTitle,
        phone: `+62` + formData?.picFinancePhoneNumber,
        email: formData?.picFinanceEmail,
      },
      personInChargeCompletion: progressData.section2,
      section: activeSectionNumber + 1,
      totalCompletion: 20,
    };
    onSubmitMerchantInfo(
      t,
      strings,
      payload,
      activeSectionNumber,
      setActiveSectionNumber,
      activeStepOutlined,
      setActiveStepOutlined,
      setFormData,
      showSnackbar
    );
  };

  return (
    <>
      <SnackbarNotification snackbar={snackbar} hideSnackbar={hideSnackbar} />

      <div className={classes.divFormStepper}>
        {objectPICInfo(t, strings)
          .reduce((rows, field, index) => {
            if (index % 2 === 0) {
              rows.push([field]);
            } else {
              rows[rows.length - 1].push(field);
            }
            return rows;
          }, [])
          .map((row, rowIndex) => (
            <div key={rowIndex} className={classes.groupWithSubtile}>
              {rowIndex === 0 && (
                <Typography className={classes.textSuperBold18}>
                  A.{" " + t(strings.textPICBusinessName)}
                </Typography>
              )}
              {rowIndex === 2 && (
                <div style={{ margin: "12px 0 0 0" }}>
                  <Typography className={classes.textSuperBold18}>
                    B.{" " + t(strings.textPICFinance)}
                  </Typography>
                </div>
              )}
              {rowIndex === 4 && (
                <div style={{ margin: "12px 0 0 0" }}>
                  <Typography className={classes.textSuperBold18}>
                    C.{" " + t(strings.textPICTechnicalName)}
                  </Typography>
                </div>
              )}

              <div className={classes.divWrapTwoColumn}>
                {row.map((field) => (
                  <div key={field.name} className={classes.divRowTextfield}>
                    <Textfields
                      isHideLabel={true}
                      placeholder={field.placeholder}
                      label={field.label}
                      type={field.type}
                      name={field.name}
                      value={formData[field.name]}
                      onChange={handleChange}
                      isValidate={field.isValidate}
                      isOptional={field.isOptional}
                      fullWidth
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}

        <div className={classes.divGroupButton}>
          <Buttons
            loadingSubmit={false}
            label={t(strings.textPrevious)}
            handleDisabledButton={() => false}
            onSubmitData={handlePreviousStep}
            leftIcon={<LeftArrow />}
            isSolid={false}
          />
          <Buttons
            loadingSubmit={formData.loadingSubmit}
            label={t(strings.textNext)}
            handleDisabledButton={handleDisabledButton}
            onSubmitData={onSubmitPICInfo}
            rightIcon={<RightIcon />}
          />
        </div>
      </div>
    </>
  );
};

export default FormPIC;

import { Box, Typography } from "@mui/material";
import classes from "./registration.module.css";
import { useTranslation } from "react-i18next";
import strings from "../General/Constant/Locales/Strings/RegistLoginStrings";
import { Link } from "react-router-dom";
import * as routeEnum from "../General/Constant/routes";
import { useState } from "react";
import Textfields from "../General/Component/Textfields";
import RadioButtons from "../General/Component/RadioButtons";
import onSubmitRegistAccount from "./Helper/onSubmitRegistAccount";
import {
  EmailSchema,
  PasswordSchema,
  PhoneNumberSchema,
} from "../General/Helper/validationField";
import handleResetForm from "../General/Helper/handleResetForm";
import DialogPopUp from "../General/Component/DialogPopUp";
import resentEmail from "./Helper/resentEmail";
import useSnackbar from "../General/Helper/useSnackbar";
import SnackbarNotification from "../General/Component/SnackbarNotification";
import FormWithIllustration from "../General/Component/FormWithIllustration";
import Buttons from "../General/Component/Buttons";

const Registration = () => {
  const { t } = useTranslation("RegistLogin");
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();

  const objectsData = [
    {
      name: "businessName",
      placeholder: t(strings.textPlaceholderBusinessName),
      label: t(strings.textBusinessName),
      type: "text",
    },
    {
      name: "fullName",
      placeholder: t(strings.textPlaceholderFullName),
      label: t(strings.textFullname),
      type: "text",
    },
    {
      name: "email",
      placeholder: t(strings.textPlaceholderEmail),
      label: t(strings.textEmail),
      type: "text",
    },
    {
      name: "phoneNumber",
      placeholder: t(strings.textPlaceholderPhoneNumber),
      label: t(strings.textPhoneNumber),
      type: "text",
    },
    {
      name: "businessType",
      placeholder: "",
      label: t(strings.textBusinessType),
      type: "text",
    },
    {
      name: "password",
      placeholder: t(strings.textPlaceholderPassword),
      label: t(strings.textPassword),
      type: "password",
    },
    {
      name: "address",
      placeholder: t(strings.textPlaceholderAddress),
      label: t(strings.textAddress),
      type: "text",
      isOptional: true,
    },
  ];

  const [formData, setFormData] = useState(
    objectsData.reduce(
      (acc, field) => {
        if (objectsData.name) acc[objectsData.name] = "";
        return acc;
      },
      { loadingSubmit: false }
    )
  );

  const [openEmail, setOpenEmail] = useState({ email: "", open: false });

  const businessTypeOption = [
    { value: "Corporate", label: t(strings.textCorporate) },
    { value: "Non Corporate", label: t(strings.textNonCorporate) },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const resetForm = () => {
    handleResetForm(formData, setFormData); // Call the reset function
  };

  const submitRegistAccount = () => {
    setFormData((prev) => ({ ...prev, loadingSubmit: true }));
    onSubmitRegistAccount(
      t,
      formData,
      setFormData,
      setOpenEmail,
      resetForm,
      showSnackbar
    );
  };

  const validEmail = EmailSchema(t).safeParse(formData.email);
  const validPassword = PasswordSchema(t).safeParse(formData.password);
  const validPhone = PhoneNumberSchema(t).safeParse(formData.phoneNumber);

  const handleDisabledButtonRegist = () => {
    return (
      formData.loadingSubmit ||
      !formData.businessName ||
      !formData.fullName ||
      !formData.businessType ||
      !validEmail.success ||
      !validPassword.success ||
      !validPhone.success
    );
  };

  const handleResentEmail = () => {
    resentEmail(t, openEmail.email, setOpenEmail, showSnackbar);
  };

  const showFormRegist = (
    <div className={classes.divFormContainer}>
      <div className={classes.divBoxForm}>
        <Typography className={classes.textBold}>
          {t(strings.textTagline)}
        </Typography>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          className={classes.boxForm}
        >
          {objectsData.map((field, index) => {
            if (field.name !== "businessType") {
              return (
                <Textfields
                  key={index}
                  isHideLabel={true}
                  placeholder={field.placeholder}
                  label={field.label}
                  type={field.type}
                  name={field.name}
                  value={formData[field.name]}
                  isOptional={field.isOptional}
                  onChange={handleChange}
                />
              );
            } else {
              return (
                <RadioButtons
                  key={index}
                  label={field.label}
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleChange}
                  options={businessTypeOption}
                  row={false}
                />
              );
            }
          })}
        </Box>
      </div>
      <div className={classes.divButtonTnC}>
        <Buttons
          loadingSubmit={formData?.loadingSubmit || false}
          label={t(strings.textRegister)}
          handleDisabledButton={handleDisabledButtonRegist}
          onSubmitData={submitRegistAccount}
        />

        <span className={classes.rowLeftAlign}>
          <Typography className={classes.textRegularBlack12}>
            {t(strings.textByCreatingAccount)}{" "}
            <Link to={""} target="_blank" className={classes.textYellow12}>
              {t(strings.textTermsOfService)}
            </Link>{" "}
            {" " + t(strings.textAnd)}
            <Link to={""} target="_blank" className={classes.textYellow12}>
              {" " + t(strings.textPrivacyPolicy)}
            </Link>
            {" iFortepay."}
          </Typography>
        </span>
      </div>
    </div>
  );

  const handleClosePopUpEmail = () => {
    setOpenEmail((prev) => ({ ...prev, open: false }));
  };

  const contentDialogEmail = (
    <div className={classes.divContentPopUp}>
      <img
        src="https://res.cloudinary.com/pastisah-id/image/upload/v1723104331/check_email_xjnxgf.png"
        className={classes.imagePopup}
        alt="img-check-email"
      />
      <div className={classes.divContentTextPopUp}>
        <Typography className={classes.textBold600H1}>
          {t(strings.textRegistSuccess)}
        </Typography>
        <Typography className={classes.textRegularDarkGray}>
          {t(strings.textClickLinkTo) + " "}
          <span className={classes.textBold600DarkGray}>{openEmail.email}</span>
          {" " + t(strings.textToActivate)}
        </Typography>
      </div>

      <div className={classes.divContentTextPopUp}>
        <Typography className={classes.textRegular12DarkGray}>
          {t(strings.textAskNoEmailSent) + " "}
          <span
            onClick={() => handleResentEmail()}
            className={classes.textYellowRegular12}
            style={{ cursor: "pointer" }}
          >
            {t(strings.textResendIt) + "."}
          </span>
        </Typography>
      </div>
    </div>
  );

  const headerFormField = (
    <div className={classes.divSignIn}>
      <Typography className={classes.textSemiBold}>
        {t(strings.textHavingAccount)}
      </Typography>
      <Link to={routeEnum.LOGIN} className={classes.textYellow14}>
        {t(strings.textYellow14)}
      </Link>
    </div>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <SnackbarNotification snackbar={snackbar} hideSnackbar={hideSnackbar} />

      <FormWithIllustration content={showFormRegist} header={headerFormField} />

      <DialogPopUp
        open={openEmail.open}
        maxWidth={"sm"}
        onClose={handleClosePopUpEmail}
        title={null}
        content={contentDialogEmail}
        actions={null}
      />
    </Box>
  );
};

export default Registration;

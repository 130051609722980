import RequestHttpGateway from "../../General/Helper/RequestHttpGateway";
import headerRequest from "../../General/Helper/headerRequest";

const getMerchantFile = (
  t,
  strings,
  fieldName,
  extension,
  merchantFileId,
  setFormData,
  showSnackbar
) => {
  let prefix = "";
  if (extension === "pdf") {
    prefix = "data:application/pdf;base64,";
  } else {
    prefix = "data:image/png;base64,";
  }

  const headers = headerRequest();

  RequestHttpGateway.get(`bucket-storage-gcp/${merchantFileId}`, { headers })
    .then((res) => {
      setFormData((prev) => ({
        ...prev,
        [fieldName]: {
          base64: `${prefix}${res.data.data.base64File}`,
          fileName: `${merchantFileId}.${extension}`,
        },
      }));
    })
    .catch((err) => {
      const message =
        err.response?.data?.response_message ||
        t(strings.textServiceNotAvailable);
      showSnackbar(message, "error");
    });
};

export default getMerchantFile;

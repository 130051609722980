import UploadIcon from "../../General/Icon/UploadIcon";

const objectMerchantWNI = (t, strings) => [
  {
    name: "merchantName",
    placeholder: t(strings.textPlaceholderMerchantName),
    label: t(strings.textMerchantName),
    type: "text",
    isValidate: true,
  },
  {
    name: "urlWeb",
    placeholder: t(strings.textPlaceholderWebsiteURL),
    label: t(strings.textURLWeb),
    type: "text",
    isValidate: true,
  },
  {
    name: "npwpNumberCompany",
    placeholder: t(strings.textPlaceholderCompanyNPWP),
    label: t(strings.textCompanyNPWP),
    type: "text",
    isValidate: true,
  },

  {
    name: "ktpNumberDirector",
    placeholder: t(strings.textPlaceholderIDNDirector),
    label: t(strings.textKTPDirector),
    type: "idCard",
    isValidate: true,
  },

  {
    name: "businessTime",
    placeholder: t(strings.textPlaceholderBusinessPeriod),
    label: t(strings.textBusinessLength),
    type: "text",
    isValidate: true,
  },
  {
    name: "employeeNumber",
    placeholder: t(strings.textPlaceholderChooseEmployeeNumber),
    label: t(strings.textNumberEmployee),
    type: "select",
    isValidate: true,
  },
  {
    name: "filePaymentFlow",
    placeholder: t(strings.textPlaceholderAddDocument),
    icon: <UploadIcon />,
    label: t(strings.textPaymentProcessFlow),
    type: "file",
    extensionFile: ".pdf",
    notes: t(strings.textInfoDocMax5MB),
    isOptional: true,
  },
  {
    name: "fileMerchantLogo",
    placeholder: t(strings.textPlaceholderCompanyLogo),
    icon: <UploadIcon />,
    label: t(strings.textMerchantLogo),
    type: "file",
    extensionFile: ".png",
    notes: t(strings.textInfoImageMax5MB),
    isOptional: true,
  },
];

export default objectMerchantWNI;

import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Typography } from "@mui/material";
import classes from "../Style/general.module.css";

const FormCheckBox = ({
  label,
  icon,
  checked,
  onChange,
  name,
  columnsPerRow,
}) => {
  return (
    <FormControlLabel
      className={
        columnsPerRow > 1
          ? classes.divFormCheckbox
          : classes.divFormCheckboxNoImage
      }
      control={
        <Checkbox
          checked={checked}
          onChange={(e) => onChange(e.target.checked, name)}
          name={name}
          sx={{
            color: "#CFDFEC",
            "&.Mui-checked": {
              color: "#FDCD07",
            },
            "&.Mui-checked:hover": {
              backgroundColor: "rgba(0, 128, 0, 0.08)",
            },
            "&:hover": {
              backgroundColor: "#FFF",
            },
          }}
        />
      }
      label={
        <div className={classes.divImageLabelChannel}>
          {icon && (
            <img src={icon} alt={label} className={classes.imageChannel} />
          )}
          <Typography className={classes.textSemiBoldDarker}>
            {label}
          </Typography>
        </div>
      }
    />
  );
};

export default FormCheckBox;

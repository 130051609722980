import FileUpload from "../../General/Component/FileUpload";
import RadioButtons from "../../General/Component/RadioButtons";
import SelectOptions from "../../General/Component/SelectOptions";
import Textfields from "../../General/Component/Textfields";
import citizenList from "../Constant/citizenList";
import numberOfEmployeeList from "../Constant/numberOfEmployeeList";

export const renderField = (
  t,
  strings,
  field,
  index,
  formData,
  handleChange,
  showSnackbar,
  handleClose
) => {
  switch (field.type) {
    case "radio":
      return (
        <RadioButtons
          key={index}
          label={field.label}
          name={field.name}
          value={formData[field.name]}
          onChange={handleChange}
          options={citizenList(t, strings)}
          row={true}
        />
      );
    case "select":
      return (
        <SelectOptions
          key={index}
          label={field.label}
          name={field.name}
          placeholder={field.placeholder}
          value={formData[field.name]}
          listData={numberOfEmployeeList(t, strings)}
          handleChange={handleChange}
        />
      );
    case "file":
      return (
        <FileUpload
          key={index}
          name={field.name}
          label={field.label}
          fileName={formData[field.name]?.fileName}
          placeholder={field.placeholder}
          icon={field.icon}
          extensionFile={field.extensionFile}
          notes={field.notes}
          isOptional={field.isOptional}
          onChange={handleChange}
          showSnackbar={showSnackbar}
          handleClose={handleClose}
        />
      );
    default:
      return (
        <Textfields
          key={index}
          isHideLabel={true}
          placeholder={field.placeholder}
          label={field.label}
          type={field.type}
          name={field.name}
          value={formData[field.name]}
          onChange={handleChange}
          isValidate={field.isValidate}
          isOptional={field.isOptional}
        />
      );
  }
};

export const renderCitizen = (
  t,
  strings,
  classes,
  field,
  index,
  formData,
  handleChange,
  showSnackbar
) => {
  switch (field.type) {
    case "radio":
      return (
        <RadioButtons
          key={index}
          label={field.label}
          name={field.name}
          value={formData[field.name]}
          onChange={handleChange}
          options={citizenList(t, strings)}
          row={true}
        />
      );
    default:
      return (
        <Textfields
          key={index}
          isHideLabel={true}
          placeholder={field.placeholder}
          label={field.label}
          type={field.type}
          name={field.name}
          value={formData[field.name]}
          onChange={handleChange}
          isValidate={true}
          isOptional={false}
        />
      );
  }
};

export const renderDirectorField = (
  t,
  strings,
  classes,
  field,
  index,
  formData,
  handleChange,
  showSnackbar
) => {
  const isWNI = formData.citizenship === "Indonesian";
  const isWNA = formData.citizenship === "Foreign";

  const wniFields = ["directorName", "ktpNumberDirector"];
  const wnaFields = [
    "directorName",
    "passportDirector",
    "guaratorName",
    "idCardGuarantor",
  ];

  if (isWNI && wniFields.includes(field.name)) {
    return (
      <div className={classes.divHalf} key={index}>
        <Textfields
          isHideLabel={true}
          placeholder={field.placeholder}
          label={field.label}
          type={field.type}
          name={field.name}
          value={formData[field.name]}
          onChange={handleChange}
          isValidate={true}
          isOptional={false}
        />
      </div>
    );
  } else if (isWNA && wnaFields.includes(field.name)) {
    return (
      <div className={classes.divHalf} key={index}>
        <Textfields
          isHideLabel={true}
          placeholder={field.placeholder}
          label={field.label}
          type={field.type}
          name={field.name}
          value={formData[field.name]}
          onChange={handleChange}
          isValidate={true}
          isOptional={false}
        />
      </div>
    );
  }
};
